"use client";
import React from "react";
import useRegisterModal from "@/hooks/useRegisterModal";
import LinksNavigation from "./navigation";
import {
  ArrowRightOnRectangleIcon,
  BanknotesIcon,
  BriefcaseIcon,
  ChatBubbleLeftEllipsisIcon,
  CurrencyDollarIcon,
  InformationCircleIcon,
  KeyIcon,
  LockClosedIcon,
  MapIcon,
  MapPinIcon,
  PhoneArrowUpRightIcon,
  PresentationChartLineIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  TruckIcon,
  UserIcon,
  UserPlusIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
import useLoginModal from "@/hooks/useLoginModal";
import { signOut } from "next-auth/react";

function useMenuOptionsData() {
  const registerModal = useRegisterModal();
  const loginModal = useLoginModal();
  const links = LinksNavigation();

  // Function to return an array of authenticated user menu options
  function AuthData() {
    return [
      {
        icon: <ChatBubbleLeftEllipsisIcon className="h-5 text-gray-600" />,
        label: "Solicitudes y mensajes",
        onClick: links.handleClickMessage,
      },
      {
        divider: true,
        icon: <UserIcon className="h-5 text-gray-600" />,
        label: "Mi perfil",
        onClick: links.handleClickProfile,
      },
      {
        icon: <WalletIcon className="h-5 text-gray-600" />,
        label: "Mi dinero",
        onClick: links.handleClickWallet,
      },
      {
        icon: <TruckIcon className="h-5 text-gray-600" />,
        label: "Vehículos",
        onClick: links.handleClickOwnerCars,
      },
      {
        icon: <MapIcon className="h-5 text-gray-600" />,
        label: "Publicaciones",
        onClick: links.handleClickOwnerPosts,
      },
      {
        icon: <BriefcaseIcon className="h-5 text-gray-600" />,
        label: "Viajes",
        onClick: links.handleClickBooking,
      },
      {
        divider: true,
        icon: <BanknotesIcon className="h-5 text-gray-600" />,
        label: "¿Porqué alquilar?",
        onClick: links.handleClickWhyRent,
      },
      {
        icon: <PresentationChartLineIcon className="h-5 text-gray-600" />,
        label: "¿Cómo Invertir?",
        onClick: links.handleClickHowInvest,
      },
      {
        divider: true,
        icon: <QuestionMarkCircleIcon className="h-5 text-gray-600" />,
        label: "Ayuda para conductores y anfitriones",
        onClick: links.handleClickHowWork,
      },
      {
        icon: <ShieldCheckIcon className="h-5 text-gray-600" />,
        label: "Coberturas y Garantía para tu Auto",
        onClick: links.handleClickInsurance,
      },
      {
        icon: <InformationCircleIcon className="h-5 text-gray-600" />,
        label: "Términos y Condiciones",
        onClick: links.handleClickTerms,
      },
      {
        icon: <LockClosedIcon className="h-5 text-gray-600" />,
        label: "Políticas de privacidad",
        onClick: links.handleClickPolicies,
      },
      {
        divider: true,
        icon: <PhoneArrowUpRightIcon className="h-5 text-gray-600" />,
        label: "Servicio al cliente",
        onClick: links.handleClickWhatsappSupport,
      },
      {
        icon: <ArrowRightOnRectangleIcon className="h-5 text-gray-600" />,
        label: "Cerrar sesión",
        onClick: () => {
          signOut();
        },
      },
    ];
  }
  // Function to return an array of non-authenticated user menu options
  function NotAuthData() {
    return [
      {
        icon: <ArrowRightOnRectangleIcon className="h-5 text-gray-600" />,
        label: "Login",
        onClick: loginModal.onOpen,
      },
      {
        icon: <UserPlusIcon className="h-5 text-gray-600" />,
        label: "Registro",
        onClick: registerModal.onOpen,
      },
      {
        divider: true,
        icon: <CurrencyDollarIcon className="h-5 text-gray-600" />,
        label: "Publicá tu auto",
        onClick: links.handleClickBecomeHost,
      },
      {
        icon: <KeyIcon className="h-5 text-gray-600" />,
        label: "!Alquilá tu auto!",
        onClick: links.handleClickWhatsappBooking,
      },
      {
        label: "!Alquilá tu auto con Cabify!",
        icon: <MapPinIcon className="h-5 text-gray-600" />,
        onClick: links.handleClickCabify,
      },
      {
        divider: true,
        icon: <BanknotesIcon className="h-5 text-gray-600" />,
        label: "¿Porqué alquilar?",
        onClick: links.handleClickWhyRent,
      },
      {
        icon: <PresentationChartLineIcon className="h-5 text-gray-600" />,
        label: "¿Cómo Invertir?",
        onClick: links.handleClickHowInvest,
      },
      {
        divider: true,
        icon: <QuestionMarkCircleIcon className="h-5 text-gray-600" />,
        label: "¿Cómo funciona ATUA?",
        onClick: links.handleClickHowWork,
      },
      {
        icon: <ShieldCheckIcon className="h-5 text-gray-600" />,
        label: "Coberturas y Garantía para tu Auto",
        onClick: links.handleClickInsurance,
      },
      {
        icon: <InformationCircleIcon className="h-5 text-gray-600" />,
        label: "Términos y Condiciones",
        onClick: links.handleClickTerms,
      },
      {
        icon: <LockClosedIcon className="h-5 text-gray-600" />,
        label: "Políticas de privacidad",
        onClick: links.handleClickPolicies,
      },
      {
        icon: <PhoneArrowUpRightIcon className="h-5 text-gray-600" />,
        label: "Servicio al cliente",
        onClick: links.handleClickWhatsappSupport,
      },
    ];
  }

  function NavBarMenuData() {
    return [
      {
        label: "¿Porqué alquilar?",
        onClick: links.handleClickWhyRent,
      },
      {
        label: "¿Cómo Invertir?",
        onClick: links.handleClickHowInvest,
      },
      {
        label: "Publicá tu auto",
        onClick: links.handleClickBecomeHost,
      },
      {
        label: "!Alquilá tu auto!",
        onClick: links.handleClickWhatsappBooking,
      },
      {
        label: "!Alquilá tu auto con Cabify!",
        onClick: links.handleClickCabify,
      },
    ];
  }
  return {
    AuthData,
    NotAuthData,
    NavBarMenuData,
  };
}

export default useMenuOptionsData;
